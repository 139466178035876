import { access } from '@data';
import { createMarkup } from '@utils/utils';
import './access.scss';

function Access(): JSX.Element {
  const { title, description } = access;

  return (
    <section className='access'>
      <div className='access__inner'>
        <h2 className='h2 access__title'>New Opportunities for Investors with ATME</h2>
        {
          description && (
            <p className='access__description' dangerouslySetInnerHTML={createMarkup(description)} />
          )
        }
      </div>
      <picture>
        <source srcSet={'/img/access/access-lg.png'}
          type='image/webp'
          media='(min-width: 1024px)'
        />
        <source srcSet={'/img/access/access-md.png'}
          type='image/webp'
          media='(min-width: 768px)'
        />
        <img
          className='access__image'
          src={'/img/access/access.png'}
          alt={title}
        />
      </picture>
    </section>
  );
}

export default Access;
