const AppRoute: { [index: string]: string } = {
  Main: '/',
  DevelopmentPage: 'development',
  ContactsPage: 'contacts',
  AboutPage: 'about',
  RaiseCapitalPage: 'raise-capital',
  PrivateEquity: 'private-equity',
  PrivateDebt: 'private-debt',
  CommoditiesPage: 'commodities',
  NotFound: '*'
};

export { AppRoute };
