import './svg.scss';

interface BaseSvgProps {
  name: string;
  width?: number;
  height?: number;
  classes?: string;
}

function BaseSvg({name, classes, width = 24, height = 24}: BaseSvgProps): JSX.Element {

  return (
    <div className={`icon ${classes || ''}`}>
      <svg width={width} height={height}>
        <use xlinkHref={`img/icons/sprite.svg#${name}`} />
      </svg>
    </div>
  );
}

export default BaseSvg;
