import classNames from 'classnames';
import type { OpportunitiesContent } from '@types';
import './opportunities.scss';

interface Props {
  data: OpportunitiesContent;
}

function Opportunities(props: Props): JSX.Element {
  const { secondary, title, description, descriptionAdditional, list } = props.data;

  const classes = classNames(
    'opportunities',
    {'opportunities--secondary': secondary}
  );

  const opportunitiesList = list.map((item) => (
    <li className='opportunities__item' key={item.image}>
      <img
        className='opportunities__item-image'
        src={`/img/opportunities/${item.image}.png`}
        alt={`${item.image}`}
      />
      <h3 className='h3 opportunities__item-title'>{ item.title }</h3>
      <p className='opportunities__item-text'>{ item.text }
      </p>
    </li>
  ));

  return (
    <section className={classes}>
      <div className='opportunities__inner'>
        <div className='opportunities__top'>
          <h2 className='h2 opportunities__title'>{ title }</h2>
          <p className='opportunities__description'>{ description }
          </p>
          { descriptionAdditional && <p className='opportunities__description-additional'>{ descriptionAdditional }</p>}
        </div>
        <ul className='opportunities__list'>
          { opportunitiesList }
        </ul>
      </div>
    </section>
  );
}

export default Opportunities;
