import BaseButton from '@components/base/button/button';
import { createMarkup } from '@utils/utils';
import type { BannerLinkContent } from '@types';
import './banner-link.scss';

interface Props {
  data: BannerLinkContent;
}

function BannerLink(props: Props): JSX.Element {

  const { title, buttonName, href, image } = props.data;

  return (
    <section className='banner-link'>
      <div className="banner-link__inner">
        <h2 className='h2 banner-link__title' dangerouslySetInnerHTML={createMarkup(title)}></h2>
        <BaseButton to={href} classes='banner-link__button' outlink primary>
          <span>{ buttonName }</span>
        </BaseButton>
      </div>
      <img
        className='promo__image'
        src={`/img/banner-link/${image}.png`}
        alt={`${image}`}
      />
    </section>
  );
}

export default BannerLink;
