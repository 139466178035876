import './main.scss';
import Header from '@components/layout/header/header';
import Footer from '@components/layout/footer/footer';
import Access from '@components/blocks/access/access';
import Promo from '@components/blocks/promo/promo';
import Abstract from '@components/blocks/abstract/abstract';
import Advantages from '@components/blocks/advantages/advantages';
import {abstract, advantages} from '@data';

function Main(): JSX.Element {

  return (
    <>
      <Header />
      <main className='main'>
        <Promo />
        <Access />
        <Abstract data={abstract} />
        <Advantages data={advantages} />
      </main>
      <Footer />
    </>
  );
}

export default Main;
