import './logo.scss';
import { Link } from 'react-router-dom';
import { AppRoute } from '@utils/const';

type LogoProps = {
  className: string;
};


function Logo({ className }: LogoProps): JSX.Element {

  return (
    <Link to={AppRoute.Main} className={`logo ${className}`}>
      <img className='logo__image' width='100' height='26' src='img/common/logo.png' alt='Логотип ATME'/>
    </Link>
  );
}

export default Logo;
