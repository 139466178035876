import classNames from 'classnames';
import { AnalyticsContent } from '@types';
import './analytics.scss';

interface Props {
  data: AnalyticsContent;
}

function Analytics(props: Props): JSX.Element {

  const { secondary, title, description, info, schedule, infoAdditional } = props.data;

  const classes = classNames(
    'analytics',
    {'analytics--secondary': secondary}
  );

  return (
    <section className={classes}>
      <div className='analytics__inner'>
        <h1 className='analytics__title' dangerouslySetInnerHTML={{__html: title}}></h1>
        <p className='analytics__description'>{ description }</p>
        <div className='analytics__schedule'>
          <div className='analytics__schedule-content'>
            <p className='analytics__schedule-description'>{ schedule.description }
            </p>
            { schedule.source && <span className='analytics__schedule-source'>{ schedule.source }</span> }
          </div>
          <picture className='analytics__image'>
            <source srcSet={`/img/analytics/${schedule.image}-xs.png`}
              type='image/webp'
              media='(max-width: 540px)'
            />
            <img
              src={`/img/analytics/${schedule.image}.png`}
              alt={`${schedule.image}`}
            />
          </picture>
        </div>
        <p className='analytics__info'>{ info }
        </p>
        <p className='analytics__info-additional'>{ infoAdditional }
        </p>
      </div>
    </section>
  );
}

export default Analytics;
