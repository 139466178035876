import ReactDOM from 'react-dom/client';
import App from '@components/layout/app/app';
import { BrowserRouter } from 'react-router-dom';
import './scss/styles.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
