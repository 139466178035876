import { Link } from 'react-router-dom';
import './directors.scss';

type DirectorsProps = { [x: string]: any };

function Directors({ directors }: DirectorsProps): JSX.Element {
  const { title, links } = directors;

  const items = links.map(({ name, to } : { [x: string]: string  }) => (
    <li key={name} className='directors__item'>
        <Link className="directors__link" to={to}>{name}</Link>
    </li>
  ));

  return (
    <section className='directors'>
      <div className='directors__inner'>
        <h2 className="h2 directors__title">{title}</h2>
        <ul className="directors__list">
          {items}
        </ul>
      </div>
    </section>
  )
}

export default Directors;
