import Header from '@components/layout/header/header';
import Footer from '@components/layout/footer/footer';
import Breadcrumbs from '@components/base/breadcrumbs/breadcrumbs';
import Story from '@components/blocks/story/story';
import Directors from '@components/blocks/directors/directors';
import Staff from '@components/blocks/staff/staff';
import { About } from '@data';
import type { BreadcrumbsItem } from '@types';

import './about.scss';

function AboutPage(): JSX.Element {
  const breadcrumbsList: BreadcrumbsItem[] = [{ name: 'ATME', path: '/' }, { name: 'About Us' }];

  const { story, directors, staff } = About;

  return (
    <>
      <Header />
      <Breadcrumbs breadcrumbs={breadcrumbsList} />
      <main className='main'>
        <h1 className='about__title'>About Us</h1>
        <Story story={story}/>
        <Directors directors={directors}/>
        <Staff staff={staff}/>
      </main>
      <Footer />
    </>
  );
}

export default AboutPage;
