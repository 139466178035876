import './abstract.scss';
import BaseSvg from '@components/base/svg/svg';
import classNames from 'classnames';
import {AbstractData} from '@types';

interface AbstractProps {
  data: AbstractData;
}

function Abstract(props: AbstractProps): JSX.Element {
  const {
    data
  } = props;

  const iconClasses = classNames(
    'abstract__item-icon'
  );

  const sectionClasses = classNames(
    'abstract',
    { 'abstract--secondary': data.secondary }
  );

  const abstractContent = data.items.map((item) => {
    const TopWrapper = item.to ? 'a' : 'div';

    return (
      <li className={'abstract__list-item'} key={item.id}>
        <div className='abstract__item'>
          <div className='abstract__item-inner'>
            <TopWrapper
              className='abstract__item-top'
              href={item.to || undefined}
              target={item.to ? '_blank' : undefined}
              rel={item.to ? 'noopener noreferrer' : undefined}
            >
              <h3 className='h3 abstract__item-title'>{item.title}</h3>
              {item.to && <BaseSvg name={'arrow-right-top'} classes={iconClasses} />}
            </TopWrapper>
            {item.text && <p className='abstract__item-text' dangerouslySetInnerHTML={{ __html: item.text }} />}
            {item.source && <p className='abstract__item-source' dangerouslySetInnerHTML={{ __html: item.source }}/>}
          </div>
        </div>
      </li>
    );
  });

  return (
    <section className={sectionClasses}>
      <div className='abstract__inner'>
        <h2 className='h2 abstract__title'>{ data.title }</h2>
        <div className='abstract__content'>
          <ul className='abstract__list'>
            { abstractContent }
          </ul>
        </div>
      </div>
      {
        data.picture &&
        <picture>
          <source srcSet={data.picture?.lg}
            type='image/webp'
            media='(min-width: 1024px) and (max-width: 1280px)'
          />
          <img
            className='abstract__image'
            src={data.picture?.original}
            alt=''
          />
        </picture>
      }
    </section>
  );
}

export default Abstract;
