import type {
  HeaderNav,
  HeaderAction,
  PromoContent,
  Access,
  AbstractData,
  AdvantagesData,
  FooterSocial,
  FooterNavItem,
  DevelopmentMain,
  Contact,
  PrivateEquity,
  Commodities
} from './types/types';
import RaiseCapitalPage from "@pages/raise-capital/raise-capital";

const headerNav: HeaderNav[] = [
  { title: 'RAISE CAPITAL', to: '/development' },
  { title: 'HOW IT WORKS', to: '/development' },
  { title: 'SERVICES', to: '/development' },
  {
    title: 'ABOUT US',
    to: '/development',
    list: [
      { title: 'Our story, mission and vision', to: '/development' },
      { title: 'Board of Directors', to: '/development' },
      { title: 'Management Board', to: '/development' },
      { title: 'Careers', to: '/development' },
      { title: 'Contact Us', to: '/contacts' },
    ]
  },
  {
    title: 'RESOURCES',
    to: '/development',
    list: [
      { title: 'Our story, mission and vision', to: '/development' },
      { title: 'Board of Directors', to: '/development' },
      { title: 'Management Board', to: '/development' },
      { title: 'Careers', to: '/development' },
      { title: 'Contact Us', to: '/contacts' },
    ]
  }
];

const headerActions: HeaderAction[] = [
  // { title: 'login', to: '/development', primary: true },
  { title: 'Send request', href: 'mailto:info@atme.com', secondary: true }
];

const promo: PromoContent[] = [
  {
    id: 1,
    majorText: 'New Opportunities for Investors, Innovative Growth for Companies',
    minorText: 'Digital Assets Exchange Licensed by the Central Bank of Bahrain',
    link: {
      href: 'mailto:info@atme.com',
      title: 'Schedule a free consultation'
    }
  },
];

const access: Access = {
  title: 'ATME is the digital assets exchange licensed by the Central Bank of Bahrain.',
  description: 'We provide access to alternative investments by fractionalizing assets through tokenization. This approach reduces barriers to entry, making these assets more accessible and liquid.<br /><br />Investors gain from streamlined buying and selling processes, enabling more efficient management of diversified portfolios and offering greater flexibility for entering and exiting investments.'
};

const abstract: AbstractData = {
  title: 'Alternative investments available through ATME',
  picture: {
    original: '/img/abstract/abstract.png',
    lg: '/img/abstract/abstract-lg.png'
  },
  items: [
    {
      id: 1,
      title: 'Private equity',
      text: 'Private equity has long been an attractive investment option for institutional investors and  high-net-worth individuals (HNWIs) due to its potential for high returns. Over the period from 2000 to 2023, private equity delivered an annualized return of 11%, significantly outperforming the 6.2% return of public equity benchmarks, such as the S&P 500. By providing access to exclusive investment opportunities, ATME empowers qualified investors to benefit from the high-growth potential of private equity markets, while also providing secondary market liquidit*',
      source: '* sources: https://www.investopedia.com/ask/answers/040615/how-do-returns-private-equity-investments-compare-returns-other-types-investments.asp https://caia.org/blog/2024/04/23/long-term-private-equity-performance-2000-2023',
      to: '/development'
    },
    {
      id: 2,
      title: 'Private debt',
      text: 'Private debt offers strategic advantages to investors, including higher returns and lower correlation with public market fluctuations. This asset class demonstrates resilience during economic downturns, making it a valuable component of a well-diversified portfolio. Through ATME, qualified investors can gain access to private debt opportunities that provide a hedge against volatility and potential for substantial returns.',
      source: '',
      to: '/development'
    },
    {
      id: 3,
      title: 'Commodities',
      text: 'Commodities remain a critical component of any balanced portfolio due to their lack of correlation with traditional financial assets like stocks and bonds. The copper market alone is projected to surpass $504 billion by 2033, while gold, with a global market capitalization of $15 trillion, continues to serve as a strong hedge against inflation and market instability. ATME offers investors fractionalized access to these robust and stable markets, fostering greater liquidity and portfolio diversification.',
      source: '',
      to: '/development'
    },
    {
      id: 4,
      title: 'Real estate',
      text: 'Real estate is a time-tested investment known for its long-term appreciation in value. As a tangible asset, it typically displays a low correlation with traditional asset classes, providing a buffer against market volatility. ATME allows investors to participate in real estate investments, enabling exposure to a sector that consistently offers reliable returns and long-term value growth.',
      source: '',
    },
    {
      id: 5,
      title: 'Art',
      text: 'Art as an asset class holds both emotional and financial value, often demonstrating a low correlation with conventional markets such as stocks and bonds. During periods of market volatility or economic downturns, the value of art may remain stable or even rise, offering a reliable hedge against market risks. Through ATME, investors can access exclusive art investment opportunities, further diversifying their portfolios with this unique and culturally significant asset class.',
      source: '',
    },
    {
      id: 6,
      title: 'And more...',
    }
  ]
};

const advantages: AdvantagesData = {
  title: 'ATME is Your first choice partner',
  picture: '/img/advantages/advantages.png',
  items: [
    {
      id: 1,
      title: 'Compliant with regulation',
      text: 'Our operations are fully licensed and regulated by the Central Bank of Bahrain, ensuring that all transactions meet the highest standards of compliance and security. This enhances investment integrity and allows you to focus on capitalizing on opportunities.',
    },
    {
      id: 2,
      title: 'Secure private blockchain technology',
      text: 'ATME leverages Hyperledger Fabric, an enterprise-grade private blockchain framework, to offer unparalleled security, transparency, and compliance in all transactions. This safeguards your investments from external threats and internal vulnerabilities.',
    },
    {
      id: 3,
      title: 'Customised services',
      text: 'Our team of dynamic and passionate industry professionals ensures that you receive dedicated support throughout your investment journey',
    },
  ]
};

const footerSocials: FooterSocial[] = [
  {
    id: 1,
    name: 'instagram',
    href: 'https://www.instagram.com/atmeexchange'
  },
  {
    id: 2,
    name: 'facebook',
    href: 'https://www.facebook.com/atmeexchange'
  },
  {
    id: 3,
    name: 'linkedin',
    href: 'https://www.linkedin.com/company/atmeexchange/'
  },
  {
    id: 4,
    name: 'xcom',
    href: 'https://x.com/atmeexchange'
  },
];

const footerNav: FooterNavItem[] = [
  {
    title: 'raise capital',
    to: '/development',
  },
  {
    title: 'How it works',
    to: '/development',
  },
  {
    title: 'Services',
    to: '/development',
  },
  {
    title: 'About Us',
    to: '/development',
    list: [
      { title: 'Our story, mission and vision', to: '/development' },
      { title: 'Board of Directors', to: '/development' },
      { title: 'Management Board', to: '/development' },
      { title: 'Careers', to: '/development' },
      { title: 'Contact Us', to: '/development' },
    ]
  },
  {
    title: 'Resources',
    to: '/development',
    list: [
      { title: 'News', to: '/development' },
      { title: 'Insights', to: '/development' },
      { title: 'FAQs', to: '/development' },
    ]
  },
  {
    title: 'Legal',
    to: '/development',
    list: [
      { title: 'Whitepapers', to: '/development' },
      { title: 'General Terms and Conditions', to: '/development' },
      { title: 'Privacy Notice', to: '/development' },
      { title: 'Fees', to: '/development' },
      { title: 'Risk Disclosure', to: '/development' },
      { title: 'Complaints Policy', to: '/development' },
      { title: 'Listing Rules', to: '/development' },
      { title: 'Market Rules', to: '/development' },
    ]
  },
];

const developmentMain: DevelopmentMain = {
  title: 'The page is under development',
  button: {
    to: '/',
    title: 'Go back to the main page'
  }
};

const contactsList: Contact[] = [
  {
    type: 'contact',
    title: 'For complaints',
    item: {
      to: 'mailto:complain@atme.com',
      name: 'email-protection',
      title: 'complain@atme.com'
    }
  },
  {
    type: 'contact',
    title: 'From for inquiries',
    item: {
      to: 'mailto:info@atme.com',
      name: 'email-info',
      title: 'info@atme.com'
    }
  },
  {
    type: 'socials',
    title: 'Social Media Links'
  },
];

const About = {
  story: {
    head: {
      title: 'Our story mission and vision',
      description: 'ATME began as a bold idea to redefine the landscape of capital markets through cutting-edge technology and an unwavering commitment to security and transparency. Recognizing the transformative potential of blockchain, we sought to reimagine how real-world assets are traded and managed. With the regulatory oversight of the Central Bank of Bahrain, ATME was established as a pioneering platform that merges principles of traditional finance with the possibilities of digital innovation to offer a secure and compliant exchange for tokenized assets.'
    },
    bottom: [
      {
        title: 'Mission',
        description: 'To transform capital markets by seamlessly connecting businesses seeking capital with investors pursuing high-value alternative assets. Through our trusted and regulated platform, we are committed to empowering all our stakeholders\' growth and diversification objectives'
      },
      {
        title: 'Vision',
        description: 'To be the global standard in financial technology, fostering a secure ecosystem where businesses and investors effortlessly interact to realize their ambitions.'
      }
    ]
  },

  directors: {
    title: 'Board of Directors',
    links: [
      {
        name: 'His Highness Shaikh Khalid bin Mohammed Al Khalifa',
        to: ' '
      },
      {
        name: 'His Highness Shaikh Salman bin Khalid Al Khalifa',
        to: ' '
      },
      {
        name: 'Bashar Al Khaja',
        to: ' '
      },
      {
        name: 'Dr. Haider Al Majali, PhD economics/ corporate law',
        to: ' '
      }
    ]
  },

  staff: {
    title: 'Management board',
    items: [
      {
        id: 1,
        name: 'Alex Lola',
        image: '/img/staff/staff-1.jpg',
        position: 'CEO, ATME',
        description: 'Alex Lola is the visionary CEO of ATME. Since the company\'s inception in 2023, he has led ATME to establish a state-of-the-art digital asset exchange platform, secure regulatory approval from the Central Bank of Bahrain, and launch with ambitious goals. Under his leadership, ATME is poised to become the leading platform for asset tokenization, drive blockchain adoption, and unlock new investment opportunities in the region<br /><br />With over 15 years of professional experience in the financial sector and multinational corporations, Alex has a proven track record of success. He has held leadership positions at Société Générale Group and its subsidiaries, including a board member role for Société Générale Russia. In this role, he demonstrated his ability to scale a retail business with assets worth $7 billion. His expertise in corporate governance is further evidenced by his tenure on the boards of Société Générale Insurance Russia and United Cards Services.<br /><br />Alex\'s journey into finance and technology began with a strong academic foundation in information security. He earned his Master\'s degree from Carnegie Mellon University, where he honed his skills and developed a deep understanding of the complexities of digital security and blockchain technology. Alex\'s technical acumen, coupled with his forward-thinking leadership, positions him as a key figure in driving ATME\'s mission to redefine trading and investment through FinTech innovation.'
      },
      {
        id: 2,
        name: 'Alaa Al-Shuwaikh',
        image: '/img/staff/staff-2.jpg',
        position: 'Chief Risk Officer, ATME',
        description: 'Alaa Al-Shuwaikh is the visionary CEO of ATME. Since the company\'s inception in 2023, he has led ATME to establish a state-of-the-art digital asset exchange platform, secure regulatory approval from the Central Bank of Bahrain, and launch with ambitious goals. Under his leadership, ATME is poised to become the leading platform for asset tokenization, drive blockchain adoption, and unlock new investment opportunities in the region<br /><br />With over 15 years of professional experience in the financial sector and multinational corporations, Alex has a proven track record of success. He has held leadership positions at Société Générale Group and its subsidiaries, including a board member role for Société Générale Russia. In this role, he demonstrated his ability to scale a retail business with assets worth $7 billion. His expertise in corporate governance is further evidenced by his tenure on the boards of Société Générale Insurance Russia and United Cards Services.<br /><br />Alex\'s journey into finance and technology began with a strong academic foundation in information security. He earned his Master\'s degree from Carnegie Mellon University, where he honed his skills and developed a deep understanding of the complexities of digital security and blockchain technology. Alex\'s technical acumen, coupled with his forward-thinking leadership, positions him as a key figure in driving ATME\'s mission to redefine trading and investment through FinTech innovation.'

      },
      {
        id: 3,
        name: 'Ashwaq Shukralla',
        image: '/img/staff/staff-3.jpg',
        position: 'Chief legal officer',
        description: 'Ashwaq is the visionary CEO of ATME. Since the company\'s inception in 2023, he has led ATME to establish a state-of-the-art digital asset exchange platform, secure regulatory approval from the Central Bank of Bahrain, and launch with ambitious goals. Under his leadership, ATME is poised to become the leading platform for asset tokenization, drive blockchain adoption, and unlock new investment opportunities in the region<br /><br />With over 15 years of professional experience in the financial sector and multinational corporations, Alex has a proven track record of success. He has held leadership positions at Société Générale Group and its subsidiaries, including a board member role for Société Générale Russia. In this role, he demonstrated his ability to scale a retail business with assets worth $7 billion. His expertise in corporate governance is further evidenced by his tenure on the boards of Société Générale Insurance Russia and United Cards Services.<br /><br />Alex\'s journey into finance and technology began with a strong academic foundation in information security. He earned his Master\'s degree from Carnegie Mellon University, where he honed his skills and developed a deep understanding of the complexities of digital security and blockchain technology. Alex\'s technical acumen, coupled with his forward-thinking leadership, positions him as a key figure in driving ATME\'s mission to redefine trading and investment through FinTech innovation.'

      },
      {
        id: 4,
        name: 'Hiba Adnan',
        image: '/img/staff/staff-4.jpg',
        position: 'Head of compliance',
        description: 'Hiba is the visionary CEO of ATME. Since the company\'s inception in 2023, he has led ATME to establish a state-of-the-art digital asset exchange platform, secure regulatory approval from the Central Bank of Bahrain, and launch with ambitious goals. Under his leadership, ATME is poised to become the leading platform for asset tokenization, drive blockchain adoption, and unlock new investment opportunities in the region<br /><br />With over 15 years of professional experience in the financial sector and multinational corporations, Alex has a proven track record of success. He has held leadership positions at Société Générale Group and its subsidiaries, including a board member role for Société Générale Russia. In this role, he demonstrated his ability to scale a retail business with assets worth $7 billion. His expertise in corporate governance is further evidenced by his tenure on the boards of Société Générale Insurance Russia and United Cards Services.<br /><br />Alex\'s journey into finance and technology began with a strong academic foundation in information security. He earned his Master\'s degree from Carnegie Mellon University, where he honed his skills and developed a deep understanding of the complexities of digital security and blockchain technology. Alex\'s technical acumen, coupled with his forward-thinking leadership, positions him as a key figure in driving ATME\'s mission to redefine trading and investment through FinTech innovation.'

      }
    ]
  }
};

const RaiseCapitalData = {
  banner: {
    title: 'Open the Gateway to Smarter Capital and Market Expansion',
    text: 'Companies across diverse industries have the opportunity to transform capital raising and market expansion through ATME’s digital assets exchange, licensed by the Central Bank of Bahrain.',
    picture: '/img/raise-capital/promo.webp'
  },
  next: {
    title: 'Take the next step',
    text: 'Issue tokens up to $5,000,000 with just $5,000',
    description: '<p><b>Disclaimer:</b> <br>' +
      '<b>Offer is valid from 1.10.2024 till 1.05.2025</b><br>' +
      '<b>Offer if valid for new issuers on the platform only\n</b></p>' +
      '<p>$5000 - ATME tokenization fee issuance volume up to $ 5 000 000 </p>' +
      '<p>Issuer covers expenses connected with legal structuring (if necessary), regulatory fees, and  marketing activities (if an issuer opts to have a marketing campaign)<br>' +
      'ATME&apos;s tokenization fee covers  issuance documentation preparation, smart contract development and deployment, issuance procedure on a primary market, and listing of a token for secondary market.</p>',
    button: {
      title: 'Start Your Trial',
      to: 'mailto:info@atme.com'
    }
  },
  why: {
    title: 'Why businesses want tokenisation?',
    picture: '/img/raise-capital/why.webp',
    items: [
      {
        title: 'To get access to new pools of investors - enhancing the potential for successful fundraising',
        text: 'ATME is simplifying the capital-raising process by leveraging asset tokenization to lower investment thresholds, making it easier for businesses <b>to attract a broader</b> investor base. Through this democratization of investment opportunities, issuers can access previously untapped markets, significantly <b>enhancing the potential for successful fundraising</b> and expanding their reach to a wider pool of investors.'
      },
      {
        title: 'To get access to new ways of raising capital - more cost effective and less time-consuming',
        text: 'By automating key processes and eliminating unnecessary intermediaries, ATME significantly <b>reduces the costs associated with capital raising</b>. Smart contracts further streamline operations, <b>minimizing administrative burdens and human errors</b>. This operational efficiency enables businesses to bring assets to market faster, securing funding more rapidly and accelerating project timelines for growth and innovation.'
      }
    ]
  },
  abstract: {
    title: 'Who can benefit from tokenization ',
    picture: {
      original: '/img/raise-capital/who.webp'
    },
    secondary: true,
    items: [
      {
        id: 1,
        title: 'For Mining companies:',
        text: 'With ATME’s innovative technology, mining companies can elevate their ESG credentials, attract a broader base of investors, and meet the increasingly stringent regulatory demands of today&apos;s market.',
        to: '/development'
      },
      {
        id: 2,
        title: 'For Businesses Looking to Issue Debt:',
        text: 'From legal structuring to KYC compliance and profit distribution, the traditional process of issuing debt can often be slow and costly. ATME streamlines these operations by offering an end-to-end solution, which reduces the time and resources typically required.',
        to: '/development'
      },
      {
        id: 3,
        title: 'For the Real Estate developers:',
        text: 'ATME democratizes access to real estate investment, attracting a wider pool of investors and enhancing overall liquidity, all while maintaining the rigorous security and compliance standards essential to the industry.',
        to: '/development'
      },
      {
        id: 4,
        title: 'For Metals producers or sellers:',
        text: 'ATME\'s seamless integration of the digital and physical worlds enables investors to enjoy the benefits of owning physical metals without the hassle of storage or transportation. This boosts liquidity and makes these assets more attractive and accessible, thereby opening up opportunities for producers to reach a wider range of investors.',
        to: '/development'
      }
    ]
  },
  advantages: {
    secondary: true,
    title: 'What sets ATME apart as your preferred partner',
    text: '<p>We don&apos;t just provide technology—we offer a comprehensive suite of services that address every facet of the capital-raising process without adding organizational complexity.</p>',
    picture: '/img/raise-capital/advantages.jpg',
    items: [
      {
        id: 1,
        title: 'End-to-End Tokenization Services',
        text: 'ATME handles every aspect of the tokenization process, from structuring and smart contract development to token issuance and secondary market trading. We facilitate fractional ownership to enhance liquidity and lower investment barriers. Meanwhile, smart contract automation streamlines processes, reduces costs, and mitigates risks.',
      },
      {
        id: 2,
        title: 'Regulatory Compliance',
        text: 'Licensed by the Central Bank of Bahrain (CBB), ATME ensures that all tokenization and trading activities meet stringent regulatory standards. Our integrated KYC and AML processes provide robust compliance, making our platform ideal for sectors with rigorous ESG and regulatory requirements.',
      },
      {
        id: 3,
        title: 'Private Blockchain Technology',
        text: 'Utilizing the enterprise-grade Hyperledger Fabric (HLF) framework, we provide issuers with a secure and permissioned private blockchain that is tailored to their needs. HLF&apos;s architecture ensures that all transactions are transparent, auditable, and compliant with regulatory standards. Its scalability and flexibility allow issuers to manage complex tokenization processes without compromising security.',
      },
    ]
  }
};

const privateEquityData: PrivateEquity = {
  analytics: {
    title: 'Redefining Access and <br /> Opportunity in Private Equity',
    description: 'Private equity represents ownership stakes in companies that are not publicly traded, offering a distinct investment opportunity for qualified investors, particularly high-net-worth individuals and institutions. This asset class has long been regarded for its potential to deliver superior returns compared to public markets.',
    info: 'During periods of economic instability, private equity has proven to be resilient, often outperforming public equities when volatility strikes. This stability makes private equity an attractive option for investors looking to diversify their portfolios and achieve competitive, long-term returns despite market uncertainty.',
    schedule: {
      description: 'According to the BMO wealth management*, private equity has outperformed public markets by over 6.96% annually over the past two decades, demonstrating its potential for high yield and consistent growth. While public markets are subject to daily price swings and short-term volatility, private equity investments are generally insulated from such fluctuations, offering a more stable return profile over time.',
      source: '*overview of private equity March, 1 2023',
      image: 'private-schedule'
    }
  },
  opportunities: {
    title: 'The ATME Competitive Edge in these Assets Opportunity',
    description: 'Private equity, while an attractive asset class, has traditionally remained out of reach for many investors due to high entry barriers and the long-term commitment required. ATME leverages the power of private blockchain technology to overcome these challenges, making private equity investments more accessible and flexible for a broader audience.',
    list: [
      {
        image: 'tokenization',
        title: 'Tokenization of Private Equity Assets',
        text: 'Through the tokenization of private equity, ATME dismantles traditional barriers by enabling fractional ownership of investments. This innovative approach lowers the cost of entry, expanding access to private equity for a wider range of investors, including those previously excluded from this high-potential asset class.'
      },
      {
        image: 'liquidity',
        title: 'Enhancing Liquidity',
        text: 'Historically, private equity has lacked liquidity, posing a significant challenge for investors. ATME addresses this by creating a secondary market for tokenized private equity assets, allowing investors to buy and sell their stakes with a click of a button. This enhanced liquidity offers flexibility, providing a solution to one of the key limitations of traditional private equity investments.'
      },
      {
        image: 'ecosystem',
        title: 'Regulated and Transparent Ecosystem',
        text: 'Operating within the regulatory framework of the Central Bank of Bahrain, ATME ensures a secure and compliant environment for all transactions. By leveraging blockchain technology, the platform offers unparalleled transparency, with immutable records of investments that simplify the complex nature of private equity while safeguarding against fraud and mismanagement.'
      },
    ]
  },
  bannerLink: {
    title: 'Ready to discover new frontiers in private equity? <br /> Let&apos;s talk!',
    buttonName: 'Discover',
    href: 'mailto:info@atme.com',
    image: 'discover'
  }
};

const privateDebtData: PrivateEquity = {
  analytics: {
    secondary: true,
    title: 'Transforming Private Debt Investments',
    description: 'Private debt refers to a form of financing directly negotiated between a lender—often an alternative asset manager—and a borrower, typically a small to mid-sized company. These loans are usually secured by the borrower&apos;s assets and carry floating interest rates, making them a flexible and attractive option for businesses seeking capital.',
    info: 'source https://www.blackrock.com/institutions/en-zz/insights/growth-drivers',
    infoAdditional: 'With the potential for higher returns, lower correlation to public market volatility, and resilience during economic downturns, private debt offers distinct advantages. These characteristics make private debt a strategic component of any well-diversified portfolio, providing investors with an opportunity to enhance returns while mitigating exposure to broader market fluctuations.',
    schedule: {
      description: 'Private debt has firmly established itself as a significant and scalable asset class, drawing increasing interest from a wide range of investors. As of December 2023, the global private debt market reached over $1.7 trillion, accounting for approximately 13% of the $13.7 trillion alternative investment landscape. This growth is fueled by the rising demand for alternative financing solutions and the appealing risk-return profile of private debt investments.',
      image: 'investments-schedule'
    }
  },
  opportunities: {
    secondary: true,
    title: 'Overcoming Traditional Barriers with ATME',
    description: 'Historically, private debt has been the domain of large institutional investors due to high entry barriers and illiquidity.',
    descriptionAdditional: 'ATME’s platform is changing that by leveraging blockchain technology to tokenize private debt assets, making them more accessible and flexible for a wider range of investors.',
    list: [
      {
        image: 'entry-barriers',
        title: 'Lowering Entry Barriers',
        text: 'Traditional private debt investments often require significant capital commitments and lengthy lock-up periods. ATME democratizes access by enabling fractional ownership, allowing investors to participate with smaller capital outlays. This opens up the market to a broader range of investors, including those who previously found private debt inaccessible.'
      },
      {
        image: 'liquidity-investments',
        title: 'Enhancing Liquidity',
        text: 'One of the primary challenges of private debt has been its illiquidity. ATME addresses this by creating a secondary market for tokenized private debt, providing investors with the flexibility to buy, sell, or trade their holdings before the debt matures. This liquidity solution is a game-changer, enabling dynamic portfolio management and greater financial flexibility.'
      },
      {
        image: 'streamlining-compliance',
        title: 'Streamlining Compliance',
        text: 'Investing in private debt can involve complex regulatory requirements, especially across different jurisdictions. ATME&apos;s private blockchain ensures all transactions are secure and fully compliant with applicable regulations, simplifying the investment process and reducing administrative burdens for investors.'
      },
    ]
  },
  bannerLink: {
    title: 'Ready to diversify your portfolio? <br /> Let&apos;s talk!',
    buttonName: 'Diversify',
    href: 'mailto:info@atme.com',
    image: 'discover'
  }
};

const commoditiesData: Commodities = {
  commodities: {
    title: 'Unlocking New Investment Horizons in Commodities',
    list: [
      {
        id: 1,
        image: 'base-metals',
        title: 'Base Metals: The Backbone of Industrial Growth',
        description: '<p>Base metals such as copper and aluminum are critical to the infrastructure and technological advancements that drive global economies. However, investing in these markets has typically required significant capital and expertise, limited access to a select group of institutional investors.</p>',
        list: [
          {
            id: 1,
            text: '<p><span>Copper</span> is indispensable in industries ranging from construction to renewable energy. The global copper market, already valued at <span>$304 billion</span>, is projected to exceed <span>$504 billion</span> by 2033*, driven by the increasing demand for sustainable technologies such as electric vehicles and renewable energy systems. ATME&apos;s platform tokenizes copper assets, allowing investors to gain exposure to this essential metal without the traditional barriers of high capital requirements and illiquidity.</p>',
            source: '<p>* source <a href=`/`>https://finance.yahoo.com/news/global-copper-market-size-exceed-113000607.html?guccounter=1</a></p>'
          },
          {
            id: 2,
            text: '<p><span>Aluminum</span>, known for its lightweight and corrosion-resistant properties, is crucial to sectors such as aerospace, automotive, and packaging. The global aluminum market continues to expand, fueled by the growing demand for lightweight materials in transport and packaging. Through ATME, investors can participate in this market by acquiring tokenized aluminum assets to invest in one of the most versatile metals in the world.</p>'
          }
        ]
      },
      {
        id: 2,
        image: 'precious-metals',
        title: 'Precious Metals: A Time-Tested Store of Value',
        description: '<p>Precious metals like gold, silver, and platinum have historically been sought for their intrinsic value and ability to hedge against economic uncertainty. However, high entry costs and logistical challenges, such as storage and security, have traditionally restricted access to these assets.</p>',
        list: [
          {
            id: 1,
            text: '<p><span>Gold</span> remains one of the most trusted assets for preserving wealth, with a global market capitalization of <span>$15 trillion*</span>. Its appeal lies in its ability to act as a hedge against inflation and currency fluctuations. ATME&apos;s tokenization platform eliminates the logistical hurdles associated with gold investment by offering digital tokens representing fractional ownership. This innovative approach provides investors with the flexibility to trade their holdings on a regulated secondary market, which enhances their portfolio adaptability.</p>',
            source: '<p>* source <a href=`/`>https://www.voronoiapp.com/markets/Global-Gold-Market-Capitalization-Hits-US-15-Trillion-in-March-2024-809</a></p>'
          },
          {
            id: 2,
            text: '<p><span>Silver</span> and <span>platinum</span> often serve as both industrial metals and stores of value. Silver&apos;s applications in electronics and renewable energy, combined with platinum&apos;s critical role in the automotive and jewelry industries, make them attractive options for diversifying an investment portfolio. ATME provides secure and compliant access to these metals through fractionalization, allowing investors to participate in their growth potential with ease and flexibility.</p>'
          }
        ]
      },
    ]
  },
  advantages: {
    title: 'The ATME Advantage investing into these assets',
    text: '<p>By converting physical assets into digital tokens, we offer investors the opportunity to purchase fractional ownership of valuable assets, eliminating the hassle of transporting and storing physical items. By recording ownership on a secure private blockchain, ATME ensures that investments are not only secure and transparent, but also fully compliant with associated regulatory requirements.</p><p>With ATME, investing in commodities is no longer restricted to the few – it&apos;s an opportunity available to all.</p>',
    picture: '/img/commodities/commodities-advantages.png',
    items: [
      {
        id: 1,
        title: 'Enhanced Liquidity',
        text: 'Tokenized assets provide investors with the ability to buy, sell, or trade their holdings on a regulated secondary market, offering a level of liquidity previously unavailable in these markets.',
      },
      {
        id: 2,
        title: 'Transparency and Security',
        text: 'ATME&apos;s platform is built on a secure private blockchain, ensuring that all transactions are transparent, secure, and compliant with applicable regulations. This technology safeguards your investments and gives you full control and visibility over your assets.',
      },
      {
        id: 3,
        title: 'Regulatory Compliance',
        text: 'Our rigorous adherence to local regulations, including comprehensive KYC procedures, ensures that your investments are fully compliant, reducing risks associated.',
      },
    ]
  },
  bannerLink: {
    title: 'Ready to diversify your portfolio? <br /> Let&apos;s talk!',
    buttonName: 'Diversify',
    href: 'mailto:info@atme.com',
    image: 'discover'
  }
};

export {
  promo,
  headerNav,
  headerActions,
  access,
  abstract,
  advantages,
  footerSocials,
  footerNav,
  developmentMain,
  contactsList,
  About,
  RaiseCapitalData,
  privateEquityData,
  privateDebtData,
  commoditiesData
};
