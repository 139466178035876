import { Route, Routes } from 'react-router-dom';
import { AppRoute } from '@utils/const';
import Main from '@pages/main/main';
import DevelopmentPage from '@pages/development/development';
import ContactsPage from '@pages/contacts/contacts';
import AboutPage from '@pages/about/about';
import RaiseCapitalPage from '@pages/raise-capital/raise-capital';
import PrivateEquity from '@pages/private-equity/private-equity';
import PrivateDebt from '@pages/private-debt/private-debt';
import CommoditiesPage from '@pages/commodities/commodities';

function App(): JSX.Element {
  return (
    <Routes>
      <Route
        index
        path={AppRoute.Main}
        element={<Main/>}
      />
      <Route
        path={AppRoute.ContactsPage}
        element={<ContactsPage />}
      />
      <Route
        path={AppRoute.AboutPage}
        element={<AboutPage />}
      />
      <Route
        path={AppRoute.RaiseCapitalPage}
        element={<RaiseCapitalPage />}
      />
      <Route
        path={AppRoute.PrivateEquity}
        element={<PrivateEquity />}
      />
      <Route
        path={AppRoute.PrivateDebt}
        element={<PrivateDebt />}
      />
      <Route
        path={AppRoute.CommoditiesPage}
        element={<CommoditiesPage />}
      />

      {/* The page for the development status  */}
      <Route
        path={AppRoute.DevelopmentPage}
        element={<DevelopmentPage />}
      />

      {/* The page for the missing page status */}
      <Route
        path={AppRoute.NotFound}
        element={<DevelopmentPage />}
      />


      <Route
        path= 'any'
      >
        <Route
          index
          element={<p>any</p>}
        />
        <Route
          path='a'
          element={<p>a</p>}
        />
      </Route>
    </Routes>
  );
}

export default App;
